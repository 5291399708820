* {
    box-sizing: border-box;
}

html, body, .root, .root-router-host {
    height: 100%;
}

.root > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-size: 16px;
    color: #000;
    background-color: #fff;
    overflow-x: hidden;
    margin: 0 auto;
    user-select: none;
}

a {
    text-decoration: none;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

.main-section {
    padding-top: 56px;
}

.container {
    max-width: 600px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.proccesing-step-page {
    padding: 10px 0;
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.item-view {
    width: 45%;
    margin-bottom: 10px;
}

.layout-page {
    padding: 20px 0;
}

.thumbnail-view-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 10px;
}

.btn-choice-template {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    background-origin: border-box;
    background-size: cover;
    border: 2px solid transparent;
    box-sizing: border-box;
    padding: 0;
    margin: 0 5px;
    &.active {
        border-color: turquoise;
    }
}

.btn-done {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #7ecd21;
    box-shadow: rgba(0,0,0,.2);
    border: none;
    position: absolute;
    bottom: 6px;
    right: 8px;
    z-index: 100;
    svg {
        width: 21px;
        fill: #fff;
    }
}

.image-view-container {
    position: relative;
    margin-bottom: 20px;
}

.loader-container {
    position: relative;
    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.result-page {
    padding: 20px 0;
    .btns-container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    a, button {
        font-family: sans-serif;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        border: none;
        width: 100%;
        height: 48px;
        background-color: violet;
        margin-top: 10px;
    }
}
